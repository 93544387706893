import React from 'react'
import Layout from '../components/Layout'
import ContactForm from '../components/Forms/contact'

export default function ContactUs(props) {
  return (
    <Layout pageContext={props.pageContext}>
      <section className="section">
        <div className="container">
          <div className="content">
            <h1 className="title">Contact</h1>
            <ContactForm />
          </div>
        </div>
      </section>
    </Layout>
  )
}
