import React from 'react'
import Form from './'

const mainFormFields = [
  {
    label: 'Name',
    required: true,
  },
  {
    label: 'Company',
    required: true,
  },
  {
    label: 'Suburb',
    required: true,
  },
  {
    label: 'Postcode',
    type: 'number',
    required: true,
  },
  {
    label: 'Phone',
    required: true,
  },
  {
    label: 'Email',
    type: 'email',
    required: true,
  },
  {
    label: 'Enquiry Details',
    type: 'textarea',
    required: false,
  },
]

const ContactForm = () => (
  <Form formFields={mainFormFields} formName={'Contact form'} />
)

export default ContactForm
